<template>
  <g>
    <svg:style>
      .cls-2, 
      .cls-3, 
      .cls-10, 
      .cls-11, 
      .cls-12, 
      .cls-13, 
      .cls-14, 
      .cls-15, 
      .cls-16,
      .cls-17,
      .cls-18,
      .cls-19,
      .cls-4, 
      .cls-5, 
      .cls-6, 
      .cls-7, 
      .cls-8, 
      .cls-9 {
        stroke: #aeaeae;
        stroke-width: 0.25px;
      }
      
      .cls-2 {
        fill: none;
      }

      .cls-3 {
        fill: url(#handle-gradient);
      }

      .cls-4 {
        fill: url(#glass-pattern);
      }

      .cls-5 {
        fill: url(#glass-pattern);
      }

      .cls-6 {
        fill: url(#glass-pattern);
      }

      .cls-7 {
        fill: url(#glass-pattern);
      }

      .cls-8 {
        fill: url(#inox-gradient);
      }

      .cls-9 {
        fill: url(#inox-gradient-2);
      }

      .cls-10 {
        fill: url(#inox-gradient-3);
      }

      .cls-11 {
        fill: url(#inox-gradient-4);
      }

      .cls-12 {
        fill: url(#inox-gradient-5);
      }

      .cls-13 {
        fill: url(#inox-gradient-6);
      }

      .cls-14 {
        fill: url(#inox-gradient-7);
      }

      .cls-15 {
        fill: url(#inox-gradient-8);
      }

      .cls-16 {
        fill: url(#inox-gradient-9);
      }

      .cls-17 {
        fill: url(#inox-gradient-10);
      }

      .cls-18 {
        fill: url(#inox-gradient-11);
      }

      .cls-19 {
        fill: url(#inox-gradient-12);
      }
    </svg:style>
    
    <linearGradient id="handle-gradient" 
                    :x1="doorLeftWidth1 + 11.33" 
                    :y1="doorTopHeight1 + 150.96" 
                    :x2="doorLeftWidth1 + 17.01" 
                    :y2="doorTopHeight1 + 150.96" 
                    gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stop-color="#babbbc"/>
      <stop offset="0.29" stop-color="#989a9e"/>
      <stop offset="0.65" stop-color="#bdbdc1"/>
      <stop offset="0.87" stop-color="#9d9fa2"/>
      <stop offset="1" stop-color="#a6a8aa"/>
      <stop offset="1" stop-color="#c2c5c9"/>
    </linearGradient>
    
    <linearGradient id="inox-gradient" 
                    :x1="doorLeftWidth1 + 22.54" 
                    :y1="doorTopHeight1 + 124.08" 
                    :x2="doorLeftWidth1 + 43.58" 
                    :y2="doorTopHeight1 + 124.08" 
                    xlink:href="#handle-gradient"/>
    <linearGradient id="inox-gradient-2" 
                    data-name="inox-gradient" 
                    :x1="doorLeftWidth1 + 98.78" 
                    :y1="doorTopHeight1 + 124.08" 
                    :x2="doorLeftWidth1 + 119.89" 
                    :y2="doorTopHeight1 + 124.08" 
                    xlink:href="#handle-gradient"/>
    <linearGradient id="inox-gradient-3" 
                    data-name="inox-gradient" 
                    :x1="doorLeftWidth1 + 22.54" 
                    :y1="doorTopHeight1 + 71" 
                    :x2="doorLeftWidth1 + 43.58" 
                    :y2="doorTopHeight1 + 71" 
                    xlink:href="#handle-gradient"/>
    <linearGradient id="inox-gradient-4" 
                    data-name="inox-gradient" 
                    :x1="doorLeftWidth1 + 98.78" 
                    :y1="doorTopHeight1 + 71" 
                    :x2="doorLeftWidth1 + 119.89" 
                    :y2="doorTopHeight1 + 71" 
                    xlink:href="#handle-gradient"/>
    <linearGradient id="inox-gradient-5" 
                    data-name="inox-gradient" 
                    :x1="doorLeftWidth1 + 22.54" 
                    :y1="doorTopHeight1 + 177.13" 
                    :x2="doorLeftWidth1 + 43.58" 
                    :y2="doorTopHeight1 + 177.13" 
                    xlink:href="#handle-gradient"/>
    <linearGradient id="inox-gradient-6" 
                    data-name="inox-gradient" 
                    :x1="doorLeftWidth1 + 98.78" 
                    :y1="doorTopHeight1 + 177.13" 
                    :x2="doorLeftWidth1 + 119.89" 
                    :y2="doorTopHeight1 + 177.13" 
                    xlink:href="#handle-gradient"/>
    <linearGradient id="inox-gradient-7" 
                    data-name="inox-gradient" 
                    :x1="doorLeftWidth1 + 22.54" 
                    :y1="doorTopHeight1 + 230.21" 
                    :x2="doorLeftWidth1 + 43.58" 
                    :y2="doorTopHeight1 + 230.21" 
                    xlink:href="#handle-gradient"/>
    <linearGradient id="inox-gradient-8" 
                    data-name="inox-gradient" 
                    :x1="doorLeftWidth1 + 98.78" 
                    :y1="doorTopHeight1 + 230.21" 
                    :x2="doorLeftWidth1 + 119.89" 
                    :y2="doorTopHeight1 + 230.21" 
                    xlink:href="#handle-gradient"/>
    <linearGradient id="inox-gradient-9" 
                    :x1="doorLeftWidth1 + 39.01" 
                    :y1="doorTopHeight1 + 123.91" 
                    :x2="doorLeftWidth1 + 102.64" 
                    :y2="doorTopHeight1 + 123.91" 
                    xlink:href="#handle-gradient"/>
    <linearGradient id="inox-gradient-10" 
                    data-name="inox-gradient" 
                    :x1="doorLeftWidth1 + 39.01" 
                    :y1="doorTopHeight1 + 70.88" 
                    :x2="doorLeftWidth1 + 102.64" 
                    :y2="doorTopHeight1 + 70.88" 
                    xlink:href="#handle-gradient"/>  
    <linearGradient id="inox-gradient-11" 
                    data-name="inox-gradient" 
                    :x1="doorLeftWidth1 + 39.01" 
                    :y1="doorTopHeight1 + 176.92" 
                    :x2="doorLeftWidth1 + 102.64" 
                    :y2="doorTopHeight1 + 176.92" 
                    xlink:href="#handle-gradient"/>
    <linearGradient id="inox-gradient-12" 
                    data-name="inox-gradient" 
                    :x1="doorLeftWidth1 + 39.01" 
                    :y1="doorTopHeight1 + 229.95" 
                    :x2="doorLeftWidth1 + 102.64" 
                    :y2="doorTopHeight1 + 229.95" 
                    xlink:href="#handle-gradient"/>
    <g id="H08">
      <!-- <g v-if="showVariant === 'outside' && showInox"> -->
      <g v-if="showInox">
        <rect id="inox" 
              class="cls-8" 
              :x="doorLeftWidth + inoxLeft" 
              :y="doorTopHeight1 + 117.73" 
              :width="inoxWidth" 
              height="12.71"/>
        <rect id="inox-2" 
              data-name="inox" 
              class="cls-9" 
              :x="doorLeftWidth1 + 98.78" 
              :y="doorTopHeight1 + 117.73" 
              :width="inoxWidth" 
              height="12.71"/>
        <rect id="inox-3" 
              data-name="inox" 
              class="cls-10" 
              :x="doorLeftWidth + inoxLeft" 
              :y="doorTopHeight1 + 64.62" 
              :width="inoxWidth" 
              height="12.78"/>
        <rect id="inox-4" 
              data-name="inox" 
              class="cls-11" 
              :x="doorLeftWidth1 + 98.78" 
              :y="doorTopHeight1 + 64.62" 
              :width="inoxWidth" 
              height="12.78"/>
        <rect id="inox-5" 
              data-name="inox" 
              class="cls-12" 
              :x="doorLeftWidth + inoxLeft" 
              :y="doorTopHeight1 + 170.78" 
              :width="inoxWidth" 
              height="12.71"/>
        <rect id="inox-6" 
              data-name="inox" 
              class="cls-13" 
              :x="doorLeftWidth1 + 98.78" 
              :y="doorTopHeight1 + 170.78" 
              :width="inoxWidth" 
              height="12.71"/>
        <rect id="inox-7" 
              data-name="inox" 
              class="cls-14" 
              :x="doorLeftWidth + inoxLeft" 
              :y="doorTopHeight1 + 223.82" 
              :width="inoxWidth" 
              height="12.78"/>
        <rect id="inox-8" 
              data-name="inox" 
              class="cls-15" 
              :x="doorLeftWidth1 + 98.78" 
              :y="doorTopHeight1 + 223.82" 
              :width="inoxWidth" 
              height="12.78"/>
      </g>
      <!-- <g v-if="showVariant === 'inside' && showInox">
        
        <rect id="inox-9" 
              class="cls-16" 
              :x="doorLeftWidth1 + 39.01" 
              :y="doorTopHeight1 + 113.3" 
              width="63.63" 
              height="21.21"/>
        <rect id="inox-10" 
              data-name="inox" 
              class="cls-17" 
              :x="doorLeftWidth1 + 39.01" 
              :y="doorTopHeight1 + 60.27" 
              width="63.63" 
              height="21.21"/>
        <rect id="inox-11" 
              data-name="inox" 
              class="cls-18" 
              :x="doorLeftWidth1 + 39.01" 
              :y="doorTopHeight1 + 166.32" 
              width="63.63" 
              height="21.21"/>
        <rect id="inox-12" 
              data-name="inox" 
              class="cls-19" 
              :x="doorLeftWidth1 + 39.01" 
              :y="doorTopHeight1 + 219.35" 
              width="63.63" 
              height="21.21"/>
      </g> -->
      <rect id="glass" 
            class="cls-4" 
            :x="doorLeftWidth1 + 43.58" 
            :y="doorTopHeight1 + 117.73" 
            :width="glassWidth" 
            height="12.71"/>
      <rect id="glass-2" 
            data-name="glass" 
            class="cls-5" 
            :x="doorLeftWidth1 + 43.58" 
            :y="doorTopHeight1 + 64.62" 
            :width="glassWidth" 
            height="12.78"/>
      <rect id="glass-3" 
            data-name="glass" 
            class="cls-6" 
            :x="doorLeftWidth1 + 43.58" 
            :y="doorTopHeight1 + 170.78" 
            :width="glassWidth" 
            height="12.71"/>
      <rect id="glass-4" 
            data-name="glass" 
            class="cls-7" 
            :x="doorLeftWidth1 + 43.58" 
            :y="doorTopHeight1 + 223.82" 
            :width="glassWidth" 
            height="12.78"/>
    </g>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
    'showBg',
    'showVariant',
    'laminationId',
  ],
  data() {
    return {
      inoxOffset: 22.18,
      leafOffset: 6.4,
      glassWidth: 55.2
    }
  },
  computed: {
    inoxH() {
      return this.showBg ?
        (this.doorHeight - this.inoxOffset * 2) :
        (this.doorHeight - this.leafOffset * 2)
    },
    inoxX1() {
      return this.showBg ?
        this.doorLeftWidth + this.inoxOffset :
        this.doorLeftWidth + this.leafOffset
    },
    inoxX2() {
      return this.showBg ?
        this.doorLeftWidth + this.inoxOffset + this.inoxW + 55.2 :
        this.doorLeftWidth + this.leafOffset + this.inoxW + 55.2
    },
    inoxW() {
      return this.showBg ?
        this.doorWidth - this.inoxOffset * 2 - 42:
        (this.doorWidth - this.leafOffset * 2 - 55.2) / 2
    },
    inoxWidth() {
      return this.showBg ?
        (this.doorWidth - this.inoxOffset * 2 - this.glassWidth) / 2 :
        (this.doorWidth - this.leafOffset * 2 - this.glassWidth) / 2
    },
    inoxLeft() {
      return this.showBg ?
        this.inoxOffset :
        this.leafOffset
    },
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth/2 - 71
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight/2 - 150
    }
  },
}
</script>
